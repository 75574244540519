<template>
  <base-section id="products">
    <base-section-heading title="Products">
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
            v-on:click="goPage(feature.url)"
          >
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProducts',
    methods: {
      goPage: function (url) {
        console.log('goPage')
        const vm = this
        return vm.$router.push(url)
      },
    },
    data: () => ({
      features: [

        {
          color: 'primary',
          dark: true,
          title: '식품 산업용 세척제',
          icon: 'mdi-silverware-clean',
          text: '빠른 효과, 높은 안정성, 광범위한 살균력의 제품들',
          url: '/Foodtech',
        },
        {
          color: 'primary',
          dark: true,
          title: '호텔단〮체 급식용 세척제',
          icon: 'mdi-human-queue',
          text: '빠른 효과, 높은 안정성, 광범위한 살균력의 제품들',
          url: '/Hotel',
        },
        {
          color: 'primary',
          dark: true,
          title: '일반산업용 세척제',
          icon: 'mdi-yurt',
          text: '빠른 효과, 높은 안정성, 광범위한 살균력의 제품들',
          url: '/GeneralIndus',
        },
        {
          color: 'primary',
          dark: true,
          title: '살균 소독제',
          icon: 'mdi-lotion-outline',
          text: '빠른 효과, 높은 안정성, 광범위한 살균력의 제품들',
          url: '/Disinfectant',
        },
      ],
    }),
  }
</script>
